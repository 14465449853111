<template>
  <Card
    :image="image"
    :title="title"
    :description="description"
    :url="course._url || course.url"
    :isLocked="isCourseLocked"
    :languages="subtitleLanguages"
    :isManual="isManual"
  >
    <template v-slot:footer-left>
      <div
        v-if="!isLoggedIn && course.attributes.public"
        class="d-flex align-items-center w-color-dark-green"
      >
        <svg-open-lock-icon class="open-lock-icon" />
        <span>Bez prihlásenia</span>
      </div>
    </template>
    <template v-slot:footer-right>
      <ToggleStarred :id="course.id" :starred="starred" class="star" />
    </template>
  </Card>
</template>

<script>
import { get } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: {
    ToggleStarred: () => import("/components/ToggleStarred.vue"),
    "svg-open-lock-icon": () => import("/assets/icons/openLock.svg?inline"),
    Card: () => import("/components/Card.vue"),
  },
  props: {
    course: { required: true, type: Object },
  },
  data() {
    return {
      image: get(
        this.course,
        "attributes.thumbnail.data.attributes.formats.small.url"
      ),
      title: get(this.course, "attributes.name"),
      description: get(this.course, "attributes.description"),
      subtitleLanguages: get(this.course, "attributes.subtitleLanguages.data"),
      isManual: !!get(this.course, "attributes.badges.data").some(
        (badge) => badge.attributes.name === "Manual"
      ),
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    isCourseLocked() {
      return !this.isLoggedIn && !this.course.attributes.public;
    },
    starred() {
      return (
        this.isLoggedIn &&
        (this.user.favoriteCourses || []).some(
          ({ id }) => id === this.course.id
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.z-card {
  width: initial !important;

  .footer {
    a {
      font-family: "Boing", sans-serif;
    }
  }
}

.star {
  position: relative;
}

.open-lock-icon {
  width: 1.5rem;
  margin-right: 0.5rem;
}
</style>
